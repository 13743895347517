<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="''">
          <template v-slot:preview>
            <v-form ref="form" lazy-validation>
              <b-card no-body class="full-width">
                <div>
                  <div
                    class="wizard wizard-4"
                    id="kt_wizard_v4"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
                  >
                    <!--begin: Wizard Nav-->
                    <div class="wizard-nav" style="background-color: #eef0f8">
                      <div class="wizard-steps">
                        <div
                          class="wizard-step"
                          data-wizard-type="step"
                          data-wizard-state="current"
                        >
                          <div class="wizard-wrapper">
                            <div class="wizard-label">
                              <div class="wizard-title">Thông tin</div>
                            </div>
                          </div>
                        </div>

                        <div class="wizard-step ml-1" data-wizard-type="step">
                          <div class="wizard-wrapper">
                            <div class="wizard-label">
                              <div class="wizard-title">Lịch sử</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card card-custom card-shadowless rounded-top-0">
                      <div class="card-body p-0">
                        <div class="row py-8 px-8 py-lg-15 px-lg-10">
                          <div class="col-xl-12">
                            <div
                              class="pb-5 full-width"
                              data-wizard-type="step-content"
                              data-wizard-state="current"
                            >
                              <b-form ref="form" lazy-validation class="row">
                                <b-container class="bv-example-row">
                                  <b-row>
                                    <b-col md="3">
                                      <div>
                                        <p
                                          class="
                                            text-dark
                                            font-size-h5 font-weight-bold
                                          "
                                        >
                                          Đơn hàng:<span
                                            style="color: blue; font-size: 12px"
                                            >#{{ id }}</span
                                          >
                                          <span style="font-size: 11px"
                                            >({{ createdAt }})</span
                                          >
                                        </p>
                                      </div>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Cửa hàng:
                                          <span class="text-danger"
                                            >*</span
                                          ></label
                                        >
                                        <vue-autosuggest
                                          :suggestions="filteredOptions"
                                          @selected="onSelected"
                                          :limit="10"
                                          @input="onInputChange"
                                          v-model="searchStore"
                                          :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder: 'Tên cửa hàng',
                                            disabled: editable,
                                          }"
                                          :should-render-suggestions="
                                            (size, loading) =>
                                              size >= 0 && !loading
                                          "
                                        ></vue-autosuggest>
                                      </b-form-group>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Nguồn đơn hàng:</label
                                        >
                                        <b-form-select
                                          id="input-1"
                                          v-model="selectProvider"
                                          :options="listProvider"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          class="select-style"
                                          disabled-field="notEnabled"
                                          v-bind:disabled="editable"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn nguồn
                                              đơn:</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Loại đơn:</label
                                        >
                                        <b-form-select
                                          id="input-1"
                                          class="select-style"
                                          v-model="selectType"
                                          :options="listType"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          disabled-field="notEnabled"
                                          v-bind:disabled="editable"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn loại</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Trạng thái đơn:</label
                                        >
                                        <b-form-select
                                          id="input-1"
                                          class="select-style"
                                          v-model="selectStatus"
                                          :options="listStatus"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          v-bind:disabled="editable"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn trạng
                                              thái</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                      <b-form-group
                                        v-show="
                                          selectStatus === 8 ||
                                            selectStatus === 7
                                        "
                                        id="input-group-name"
                                      >
                                        <label for="input-name">Lý do:</label>
                                        <b-form-select
                                          id="input-1"
                                          class="select-style"
                                          v-model="selectCancelStatus"
                                          :options="listCancelStatus"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          disabled-field="notEnabled"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn lý do:</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Ngày đến cửa hàng:
                                          <span class="text-danger"
                                            >*</span
                                          ></label
                                        >
                                        <div class="input-group">
                                          <date-picker
                                            id="datecome-input"
                                            placeholder="Ngày đến cửa hàng"
                                            class="form-control-sm input-style"
                                            :config="dpConfigs.dateCome"
                                            v-model="dateCome"
                                            :wrap="true"
                                            v-bind:disabled="editable"
                                          ></date-picker>
                                          <div class="input-group-append">
                                            <button
                                              class="
                                                btn btn-secondary btn-sm
                                                datepickerbutton
                                              "
                                              type="button"
                                              title="Toggle"
                                            >
                                              <i class="far fa-calendar"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </b-form-group>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Giờ đến cửa hàng:
                                        </label>
                                        <div class="input-group">
                                          <date-picker
                                            :config="dpConfigs.timePicker"
                                            class="form-control-sm input-style"
                                            v-model="timeCome"
                                            :wrap="true"
                                            placeholder="Giờ đến cửa hàng"
                                            v-bind:disabled="editable"
                                          ></date-picker>
                                          <div class="input-group-append">
                                            <button
                                              class="
                                                btn btn-secondary btn-sm
                                                datepickerbutton
                                              "
                                              type="button"
                                              title="Toggle"
                                            >
                                              <i class="far fa-clock"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </b-form-group>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Mã vận đơn:</label
                                        >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style"
                                          size="sm"
                                          type="text"
                                          v-model="codeShip"
                                          required
                                          placeholder="Mã vận đơn"
                                          v-bind:disabled="editable"
                                        ></b-form-input>
                                      </b-form-group>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Ghi chú CSKH:</label
                                        >
                                        <b-form-textarea
                                          size="sm"
                                          id="textarea1"
                                          class="input-style"
                                          v-model="note"
                                          :placeholder="'Thêm ghi chú'"
                                          :rows="6"
                                          :max-rows="6"
                                          v-bind:disabled="editable"
                                        ></b-form-textarea>
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="3">
                                      <div>
                                        <p
                                          class="
                                            text-dark
                                            font-size-h5 font-weight-bold
                                          "
                                        >
                                          Khách hàng
                                        </p>
                                      </div>
                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Điện thoại:
                                          <span class="text-danger"
                                            >*</span
                                          ></label
                                        >
                                        <vue-autosuggest
                                          :suggestions="filteredOptionsCustomer"
                                          :get-suggestion-value="
                                            getSuggestionValue
                                          "
                                          @selected="onSelectedCustomer"
                                          :limit="10"
                                          v-model="customerPhone"
                                          @input="onInputChangCustomer"
                                          :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder: 'SĐT Khách hàng',
                                            disabled: editable,
                                          }"
                                          :should-render-suggestions="
                                            (size, loading) =>
                                              size >= 0 && !loading
                                          "
                                        >
                                          <div
                                            slot-scope="{ suggestion }"
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            <span class="mr-1">
                                              {{
                                                suggestion.item.phoneNo
                                              }}</span
                                            >
                                            <span
                                              class="text-primary"
                                              v-if="
                                                suggestion.item.fullName &&
                                                  suggestion.item.fullName.trim()
                                                    .length > 0
                                              "
                                            >
                                              - {{ suggestion.item.fullName }}
                                            </span>
                                          </div>
                                        </vue-autosuggest>
                                      </b-form-group>

                                      <b-form-group id="input-group-1">
                                        <label for="input-name">Họ tên:</label>
                                        <b-form-input
                                          id="input-2"
                                          class="input-style"
                                          size="sm"
                                          type="text"
                                          v-model="customerName"
                                          required
                                          placeholder="Họ tên khách hàng"
                                          v-bind:disabled="editable"
                                        ></b-form-input>
                                      </b-form-group>

                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Thành phố:</label
                                        >
                                        <b-form-select
                                          id="input-1"
                                          class="select-style"
                                          v-model="selectCity"
                                          :options="listCity"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          disabled-field="notEnabled"
                                          v-on:change="getDistrict"
                                          v-bind:disabled="editable"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn thành
                                              phố</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>

                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Quận huyện:</label
                                        >
                                        <b-form-select
                                          id="input-1"
                                          class="select-style"
                                          v-model="selectDistrict"
                                          :options="listDistrict"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          disabled-field="notEnabled"
                                          v-on:change="getWard"
                                          v-bind:disabled="editable"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn quận</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>

                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Phường xã:</label
                                        >
                                        <b-form-select
                                          id="input-1"
                                          class="select-style"
                                          v-model="selectWard"
                                          :options="listWard"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          disabled-field="notEnabled"
                                          v-bind:disabled="editable"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn phường</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>

                                      <b-form-group id="input-group-1">
                                        <label for="input-name">Địa chỉ:</label>
                                        <b-form-textarea
                                          size="sm"
                                          id="textarea2"
                                          class="input-style"
                                          v-model="address"
                                          placeholder="Địa chỉ khách hàng"
                                          :rows="6"
                                          :max-rows="6"
                                          v-bind:disabled="editable"
                                        ></b-form-textarea>
                                      </b-form-group>

                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Ghi chú của khách:</label
                                        >
                                        <b-form-textarea
                                          size="sm"
                                          id="textarea1"
                                          class="input-style"
                                          v-model="noteCustomer"
                                          :placeholder="'Thêm ghi chú'"
                                          :rows="6"
                                          :max-rows="6"
                                          v-bind:disabled="editable"
                                        ></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="3">
                                      <div>
                                        <p
                                          class="
                                            text-dark
                                            font-size-h5 font-weight-bold
                                          "
                                        >
                                          Thanh toán
                                        </p>
                                      </div>
                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Kiểu chiết khấu:</label
                                        >
                                        <b-form-select
                                          id="input-1"
                                          class="select-style"
                                          v-model="selectDiscountType"
                                          :options="listDiscountType"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          disabled-field="notEnabled"
                                          v-bind:disabled="editable"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn kiểu chiết
                                              khấu</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>

                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Chiết khấu:</label
                                        >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style text-right"
                                          size="sm"
                                          type="text"
                                          v-model="discount"
                                          required
                                          placeholder="Chiết khấu"
                                          v-bind:disabled="editable"
                                        ></b-form-input>
                                      </b-form-group>

                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Ngày hẹn thanh toán:</label
                                        >
                                        <div class="input-group">
                                          <date-picker
                                            placeholder="Ngày hẹn thanh toán"
                                            class="form-control-sm input-style"
                                            :config="dpConfigs.datePay"
                                            v-model="datePay"
                                            :wrap="true"
                                            v-bind:disabled="editable"
                                          ></date-picker>
                                          <div class="input-group-append">
                                            <button
                                              class="
                                                btn btn-secondary btn-sm
                                                datepickerbutton
                                              "
                                              type="button"
                                              title="Toggle"
                                            >
                                              <i class="far fa-calendar"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </b-form-group>

                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Phí vận chuyển thu của khách</label
                                        >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style text-right"
                                          size="sm"
                                          type="text"
                                          v-model="customerShipFee"
                                          placeholder="Phí vận chuyển thu của khách"
                                          v-mask="mask"
                                          v-bind:disabled="editable"
                                        ></b-form-input>
                                      </b-form-group>

                                      <b-form-group id="input-group-name">
                                        <label for="input-name"
                                          >Phí trả HVC</label
                                        >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style text-right"
                                          size="sm"
                                          type="text"
                                          v-model="shipFee"
                                          placeholder="Phí trả HVC"
                                          v-mask="mask"
                                          v-bind:disabled="editable"
                                        ></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="3">
                                      <div>
                                        <p
                                          class="
                                            text-dark
                                            font-size-h5 font-weight-bold
                                          "
                                        >
                                          Hình thức thanh toán
                                        </p>
                                      </div>
                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Tiền đặt cọc:</label
                                        >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style text-right"
                                          size="sm"
                                          type="text"
                                          v-model="deposit"
                                          required
                                          placeholder="Tiền đặt cọc"
                                          v-mask="mask"
                                        ></b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                        v-show="checkDepositPrice()"
                                        id="input-group-1"
                                      >
                                        <label for="input-cash-account"
                                          >Tài khoản thanh toán tiền mặt:</label
                                        >
                                        <vue-autosuggest
                                          v-model="selectedCashAcc"
                                          :suggestions="filteredCashAccOptions"
                                          @selected="onCashAccSelected"
                                          :limit="100"
                                          @input="onInputCashAccChange"
                                          :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder:
                                              'Nhập tài khoản chuyển khoản',
                                          }"
                                        ></vue-autosuggest>
                                      </b-form-group>
                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Tiền chuyển khoản:</label
                                        >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style text-right"
                                          size="sm"
                                          type="text"
                                          v-model="priceDebit"
                                          required
                                          placeholder="Nhập số tiền chuyển khoản"
                                          v-mask="mask"
                                        ></b-form-input>
                                      </b-form-group>

                                      <b-form-group
                                        v-show="checkDebitPrice()"
                                        id="input-group-1"
                                      >
                                        <label for="input-transfer-account"
                                          >Tài khoản chuyển khoản:</label
                                        >
                                        <vue-autosuggest
                                          v-model="selectedAccountant"
                                          :suggestions="filteredAccOptions"
                                          @selected="onAccSelected"
                                          :limit="100"
                                          @input="onInputAccChange"
                                          :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder:
                                              'Nhập tài khoản chuyển khoản',
                                          }"
                                        ></vue-autosuggest>
                                      </b-form-group>
                                      <b-form-group
                                        id="input-group-1"
                                        v-if="checkDebitPrice()"
                                      >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style"
                                          size="sm"
                                          type="text"
                                          v-model="transferReferenceCode"
                                          required
                                          placeholder="Mã tham chiếu"
                                        ></b-form-input>
                                      </b-form-group>
                                      <b-form-group id="input-group-1">
                                        <label for="input-name"
                                          >Tiền quẹt thẻ:</label
                                        >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style text-right"
                                          size="sm"
                                          type="text"
                                          v-model="creditAmount"
                                          required
                                          placeholder="Tiền quẹt thẻ"
                                          v-mask="mask"
                                          @change="onCreditAmountChange"
                                        ></b-form-input>
                                      </b-form-group>

                                      <b-form-group
                                        v-if="checkCreditPrice()"
                                        id="input-group-1"
                                      >
                                        <label for="input-cash-account"
                                          >Tài khoản quẹt thẻ:</label
                                        >
                                        <vue-autosuggest
                                          aria-describedby="input-credit-live-feedback"
                                          v-model="selectedCreditAcc"
                                          :suggestions="
                                            filteredCreditAccOptions
                                          "
                                          @selected="onCreditAccSelected"
                                          :limit="10"
                                          @input="onInputCreditAccChange"
                                          :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder:
                                              'Nhập tài khoản quẹt thẻ',
                                          }"
                                          :should-render-suggestions="
                                            (size, loading) =>
                                              size >= 0 && !loading
                                          "
                                        >
                                          <div
                                            slot-scope="{ suggestion }"
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            {{ suggestion.item.name }}
                                          </div>
                                        </vue-autosuggest>
                                      </b-form-group>
                                      <b-form-group
                                        id="input-group-1"
                                        v-if="checkCreditPrice()"
                                      >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style"
                                          size="sm"
                                          type="text"
                                          v-model="creditCode"
                                          required
                                          placeholder="Mã giao dịch"
                                        ></b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                        id="input-group-1"
                                        v-if="checkCreditPrice()"
                                      >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style"
                                          size="sm"
                                          type="text"
                                          v-model="creditCardCode"
                                          required
                                          placeholder="Nhập 4 số cuối mã thẻ"
                                        ></b-form-input>
                                      </b-form-group>

                                      <b-form-group
                                        id="input-group-name"
                                        v-if="checkCreditPrice()"
                                      >
                                        <label for="input-name"
                                          >Hình thức trả phí:</label
                                        >
                                        <b-form-select
                                          id="input-1"
                                          class="select-style"
                                          v-model="selectedPayCreditFeeType"
                                          :options="listPayCreditFeeType"
                                          size="sm"
                                          value-field="id"
                                          text-field="name"
                                          disabled-field="notEnabled"
                                          @change="onCreditFeePayTypeChange()"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option
                                              :value="null"
                                              disabled
                                              >Chọn hình thức trả phí quẹt
                                              thẻ</b-form-select-option
                                            >
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                      <b-form-group
                                        id="input-group-1"
                                        v-if="checkCreditPrice()"
                                      >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style text-right"
                                          size="sm"
                                          type="text"
                                          v-model="creditCardFee"
                                          required
                                          placeholder="Phí quẹt thẻ"
                                          v-mask="mask"
                                        ></b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                        v-if="checkCreditPrice()"
                                        id="input-group-1"
                                      >
                                        <label for="input-cash-account"
                                          >Tài khoản thu phí quẹt thẻ:</label
                                        >
                                        <vue-autosuggest
                                          aria-describedby="input-credit-live-feedback"
                                          v-model="selectedCreditFeeAcc"
                                          :suggestions="
                                            filteredCreditFeeAccOptions
                                          "
                                          @selected="onCreditFeeAccSelected"
                                          :limit="10"
                                          @input="onInputCreditFeeAccChange"
                                          :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder:
                                              'Nhập tài khoản thu phí',
                                          }"
                                          :should-render-suggestions="
                                            (size, loading) =>
                                              size >= 0 && !loading
                                          "
                                        >
                                          <div
                                            slot-scope="{ suggestion }"
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            {{ suggestion.item.name }}
                                          </div>
                                        </vue-autosuggest>
                                      </b-form-group>
                                      <b-form-group id="input-group-1">
                                        <label for="input-name">Trả góp</label>
                                        <b-form-input
                                          id="input-2"
                                          class="input-style text-right"
                                          size="sm"
                                          type="text"
                                          v-model="installedMoneyAmount"
                                          required
                                          placeholder="Trả góp"
                                          v-mask="mask"
                                        ></b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                        v-if="checkInstallmentAmount()"
                                        id="input-group-1"
                                      >
                                        <label for="input-intallment"
                                          >Dịch vụ trả góp:</label
                                        >
                                        <vue-autosuggest
                                          aria-describedby="input-installment-live-feedback"
                                          v-model="selectedInstallment"
                                          :suggestions="
                                            filteredOptionsInstallment
                                          "
                                          @selected="onInstallmentSelected"
                                          :limit="100"
                                          @input="onInputInstallmentChange"
                                          :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder: 'Nhập dịch vụ trả góp',
                                          }"
                                        >
                                          <div
                                            slot-scope="{ suggestion }"
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            {{ suggestion.item.name }}
                                          </div>
                                        </vue-autosuggest>
                                      </b-form-group>
                                      <b-form-group
                                        id="input-group-2"
                                        v-if="checkInstallmentAmount()"
                                      >
                                        <b-form-input
                                          id="input-2"
                                          class="input-style"
                                          size="sm"
                                          type="text"
                                          v-model="installmentCode"
                                          required
                                          placeholder="Mã hợp đồng"
                                        ></b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                        id="input-group-3"
                                        v-if="checkInstallmentAmount()"
                                      >
                                        <b-form-input
                                          id="input-3"
                                          class="input-style"
                                          size="sm"
                                          type="text"
                                          v-model="customerIndentifyNo"
                                          required
                                          placeholder="Số CMND"
                                        ></b-form-input>
                                      </b-form-group>
                                      <b-row>
                                        <b-col md="3">
                                          <p class="mb-0 font-weight-bolder">
                                            Số SP:
                                          </p>
                                          <p
                                            class="
                                              text-success
                                              font-weight-bolder
                                            "
                                          >
                                            {{ listOrderItem.length }}
                                          </p>
                                        </b-col>
                                        <b-col md="9">
                                          <b-row>
                                            <b-col md="6">
                                              <p
                                                class="mb-0 font-weight-bolder"
                                              >
                                                Thu khách:
                                              </p>
                                              <p
                                                class="
                                                  text-success
                                                  font-weight-bolder
                                                "
                                              >
                                                {{ formatMoney(collectPrice) }}
                                              </p>
                                            </b-col>
                                            <b-col md="6">
                                              <p
                                                class="mb-0 font-weight-bolder"
                                              >
                                                Trả shop:
                                              </p>
                                              <p
                                                class="
                                                  text-success
                                                  font-weight-bolder
                                                "
                                              >
                                                {{ formatMoney(refundPrice) }}
                                              </p>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                      </b-row>
                                      <p class="mb-0 font-weight-bolder">
                                        Người tạo:
                                      </p>
                                      <p
                                        class="text-success font-weight-bolder"
                                      >
                                        {{ createdBy }}
                                      </p>
                                    </b-col>
                                  </b-row>
                                </b-container>

                                <b-container
                                  v-show="searchStore !== null"
                                  class="pr-0"
                                >
                                  <b-row class="col-md-12 mb-5 pr-0">
                                    <b-col class="col-md-3 p-0">
                                      <b-form-select
                                        id="select-product"
                                        v-model="selectTypeSearch"
                                        :options="listTypeSearch"
                                        size="sm"
                                        value-field="id"
                                        text-field="name"
                                        disabled-field="notEnabled"
                                        @change="changePlaceholder"
                                      ></b-form-select>
                                    </b-col>
                                    <b-col class="p-0">
                                      <vue-autosuggest
                                        v-model="searchProduct"
                                        :disabled="
                                          selectedClassStore === null ||
                                            selectedClassStore === ''
                                        "
                                        :suggestions="filteredOptionsProduct"
                                        @selected="onSelectedProduct"
                                        :limit="10"
                                        @input="onInputChangProduct"
                                        :input-props="{
                                          id: 'autosuggest__input',
                                          placeholder: 'Tìm kiếm',
                                          style: 'border-radius:0px!important',
                                          disabled: editable,
                                        }"
                                        :should-render-suggestions="
                                          (size, loading) =>
                                            size >= 0 &&
                                            !loading &&
                                            searchProduct !== ''
                                        "
                                      >
                                        <template slot-scope="{ suggestion }">
                                          <div>
                                            ({{
                                              formatMoney(
                                                suggestion.item.sellingPrice,
                                              )
                                            }}) (
                                            <span
                                              v-if="
                                                suggestion.item
                                                  .quantityInStock > 0
                                              "
                                              class="text-success"
                                              >Tồn TCB:
                                              {{
                                                suggestion.item.quantityInStock
                                              }}</span
                                            >
                                            <span v-else class="text-danger"
                                              >Tồn TCB:
                                              {{
                                                suggestion.item.quantityInStock
                                              }}</span
                                            >
                                            )
                                            {{ suggestion.item.productName }}
                                          </div>
                                        </template>
                                      </vue-autosuggest>
                                    </b-col>
                                    <b-col
                                      class="col-md-1 p-0"
                                      style="display: contents"
                                    >
                                      <div
                                        id="viewInventory"
                                        style="cursor: pointer"
                                      >
                                        <b-button
                                          @click="viewInventory()"
                                          class="btn btn-sm btn-secondary"
                                          squared
                                        >
                                          <i class="fa fa-eye"></i>
                                          <span class="font-weight-bold"
                                            >Xem tồn</span
                                          >
                                        </b-button>
                                      </div>
                                    </b-col>
                                  </b-row>

                                  <div class="row col-md-12 pr-0">
                                    <table
                                      class="
                                        table
                                        table-bordered
                                        table-vertical-center
                                        table-hover
                                      "
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style="textalign: center"
                                            class="headerTable"
                                          >
                                            STT
                                          </th>
                                          <th class="name headerTable">
                                            Sản phẩm
                                          </th>
                                          <th class="value headerTable">
                                            Số lượng
                                          </th>
                                          <th class="code headerTable">Giá</th>
                                          <th class="code headerTable">
                                            Thành tiền
                                          </th>
                                          <th class="code headerTable">
                                            Chiết khấu
                                          </th>
                                          <th></th>
                                        </tr>
                                      </thead>

                                      <tbody
                                        v-for="(item, index) in listOrderItem"
                                        :key="item.tempId || item.id"
                                      >
                                        <OrderItem
                                          v-if="!item.deleted"
                                          v-bind:productItem="item"
                                          v-bind:index="index"
                                          v-bind:editable="!editable"
                                          v-on:deleteItem="deleteItemInArray"
                                          v-on:update="updateItem"
                                          v-on:validateState="validateState"
                                          v-on:checkValidImei="checkValidImei"
                                          v-on:addProductPromotion="
                                            showModalAddProductPromotion
                                          "
                                          v-on:addNote="showModalAddNote"
                                        />
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <th
                                            style="text-align: center"
                                            id="total"
                                            colspan="4"
                                          >
                                            Tổng :
                                          </th>
                                          <td
                                            style="
                                              text-align: right;
                                              padding: 0px 20px;
                                              font-weight: 600;
                                            "
                                          >
                                            {{ formatMoney(totalPrice) }}
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </b-container>
                                <b-container class="bv-example-row">
                                  <hr
                                    class="hr-text"
                                    data-content="Bút Toán"
                                    style="font-weight: 600"
                                  />
                                  <b-row>
                                    <b-col md="12" lg="12" sm="12">
                                      <OrderTransactions
                                        :orderNumber="$route.query.id"
                                      />
                                    </b-col>
                                  </b-row>
                                </b-container>
                              </b-form>
                            </div>

                            <div
                              class="pb-5 full-width"
                              data-wizard-type="step-content"
                            >
                              <b-container class="bv-example-row">
                                <b-table
                                  :fields="historyFields"
                                  :items="histories"
                                  class="table-bordered table-hover col-md-6"
                                >
                                  <template v-slot:cell(updatedBy)="row">
                                    <p>{{ row.item.updatedBy.fullName }}</p>
                                    <p>{{ row.item.updatedAt }}</p>
                                  </template>
                                  <template v-slot:cell(action)="row">
                                    <p>
                                      {{
                                        row.item.action === 1
                                          ? 'Sửa đơn hàng'
                                          : 'Xóa đơn hàng'
                                      }}
                                    </p>
                                  </template>

                                  <template v-slot:cell(status)="row">
                                    <p>
                                      {{
                                        getOrderStatus(row.item.newValue.status)
                                      }}
                                    </p>
                                  </template>
                                </b-table>
                              </b-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Nav -->
              </b-card>
            </v-form>
            <b-modal
              v-model="showInventory"
              hide-footer
              title="Tồn kho sản phẩm"
            >
              <table
                class="table table-bordered table-vertical-center table-hover"
              >
                <thead>
                  <tr>
                    <th
                      style="
                        textalign: center;
                        color: rgb(24, 28, 50);
                        width: 30%;
                      "
                    >
                      Sản phẩm
                    </th>
                    <th
                      v-for="(item, index) in listOrderItem"
                      :key="index"
                      style="textalign: center; color: rgb(24, 28, 50)"
                    >
                      {{ item.name }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <!-- <tr>
                    <td>Số khách đặt</td>
                    <td></td>
                  </tr>-->
                  <tr>
                    <td>
                      <span class="font-weight-bold">Tổng có thể bán</span>
                    </td>
                    <td
                      style="text-align: right"
                      v-for="(item, index) in listOrderItem"
                      :key="index"
                    >
                      {{ item.quantityInStock }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-row>
                <b-col>
                  <div class="float-right">
                    <b-button
                      @click="showInventory = !showInventory"
                      style="fontweight: 600; width: 90px"
                      variant="primary"
                      size="sm"
                      >Xác nhận</b-button
                    >
                    <b-button
                      @click="showInventory = !showInventory"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                      >Hủy</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </b-modal>
            <b-modal
              ref="add-product-promotion-modal"
              hide-footer
              :title="modalTitle"
              size="xl"
            >
              <v-form ref="form" lazy-validation>
                <!-- <b-form-group id="input-group-3" label="Chọn sản phẩm bán kèm:" label-for="input-3">
                  <vue-autosuggest
                    v-model="selectedProductPromotion"
                    :suggestions="filteredOptionsProduct"
                    @selected="onSelectedProductPromotion"
                    :limit="10"
                    @input="onInputChangeProductPromotion"
                    :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder: 'Chọn sản phẩm bán kèm'
                                          }"
                    :should-render-suggestions="
                                            (size, loading) =>
                                              size >= 0 &&
                                              !loading &&
                                              selectedProductPromotion !== ''
                                          "
                  >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                      <div>
                        <span class="text-primary">
                          ({{formatMoney(suggestion.item.sellingPrice)}})
                          (
                        </span>
                        <span
                          v-if="suggestion.item.quantityInStock > 0"
                          class="text-success"
                        >Tồn TCB: {{suggestion.item.quantityInStock}}</span>
                        <span
                          v-else
                          class="text-danger"
                        >Tồn TCB: {{suggestion.item.quantityInStock}}</span>
                        )
                        {{suggestion.item.productName}}
                      </div>
                    </div>
                  </vue-autosuggest>
                </b-form-group>-->
                <b-table
                  :items="listProductPromotion"
                  :fields="fieldProductPromotion"
                  class="table-bordered table-hover col-md-12"
                >
                  <template v-slot:head(selected)>
                    <span>
                      <b-form-checkbox
                        v-model="checkAllPro"
                        size="lg"
                        @change="clickAll"
                      ></b-form-checkbox>
                    </span>
                  </template>
                  <!-- check bõ -->
                  <template v-slot:cell(selected)="row">
                    <div class="d-flex justify-content-center">
                      <b-form-checkbox
                        size="lg"
                        v-model="row.item.checked"
                        @change="clickIndex(row.item.id)"
                      ></b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(id)="row">
                    <div>
                      <span
                        v-text="row.item.id"
                        class="d-flex justify-content-center"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(price)="row">
                    <div>
                      <span
                        v-text="formatMoney(row.item.price)"
                        class="text-right"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(returnPrice)="row">
                    <div>
                      <span
                        v-text="formatMoney(row.item.returnPrice)"
                        class="text-right"
                      ></span>
                    </div>
                  </template>
                </b-table>
                <b-button
                  style="fontweight: 600; width: 100px"
                  variant="primary"
                  size="sm"
                  @click="addProductPromotionToListBillItem"
                  >Áp dụng</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddProductPromotion"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="add-note-modal"
              hide-footer
              :title="'Thêm ghi chú cho sản phẩm ' + productNote"
            >
              <b-row class="mb-5">
                <b-col>
                  <label class="labelInput" for="inputOriginalPrice"
                    >Ghi chú</label
                  >
                  <b-form-textarea
                    id="textarea"
                    v-model="noteBillItem"
                    placeholder="Nhập ghi chú..."
                    rows="5"
                    max-rows="6"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="updateNote"
                    >Lưu</b-button
                  >
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hidenModalAddNote"
                    >Hủy</b-button
                  >
                </b-col>
              </b-row>
            </b-modal>
          </template>

          <template v-slot:foot>
            <b-container class="bv-example-row">
              <b-row>
                <b-col>
                  <b-button
                    tabindex="15"
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    type="submit"
                    @click="validate"
                    v-if="!editable"
                    >Lưu</b-button
                  >
                  <router-link to="/orders" tag="button">
                    <b-button
                      tabindex="16"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                      v-if="!editable"
                      >Hủy</b-button
                    >
                    <b-button
                      tabindex="16"
                      style="font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                      v-else
                      >Trở về</b-button
                    >
                  </router-link>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
#select-product {
  border-radius: 0px;
  /* border-bottom-right-radius:0px;
border-top-right-radius:0px; */
  box-shadow: none;
  border: 1px solid #ced4da;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.input-style {
  border: 1px solid #ced4da;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #babac5;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #babac5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #babac5;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
// import { formatMoney } from '../../../utils/common';
import { validationMixin } from 'vuelidate';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
// import ApiService from '@/core/services/api.service';
import OrderItem from '../../components/OrderItem.vue';
import { vi } from 'vuejs-datepicker/dist/locale';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import { removeAccents } from './../../../utils/common';
import { v4 as uuidv4 } from 'uuid';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import decounce from 'debounce';
import OrderTransactions from './../../components/OrderTransactions.vue';
import { TIME_TRIGGER } from './../../../utils/constants';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

export default {
  mixins: [validationMixin],
  data() {
    return {
      editable: false,
      histories: [],
      historyFields: [
        {
          key: 'updatedBy',
          label: 'Người tạo',
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'action',
          label: 'Hành động',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
        },
      ],
      errors: [],
      selectedCashAcc: '',
      filteredCashAccOptions: [],
      filteredAccOptions: [],
      cashAccOptions: [
        {
          data: [],
        },
      ],
      listCashAccSearch: [],
      listAccSearch: [],
      selectedAccountant: '',
      accountantOptions: [
        {
          data: [],
        },
      ],
      isValidImei: true,
      mask: currencyMask,
      createdBy: '',
      createdAt: '',
      showInventory: false,
      customerPhone: '',
      customerId: '',
      id: '',
      customerShipFee: 0,
      shipFee: 0,
      listStore: [],
      selectProvider: null,
      listProvider: [],
      selectKT: null,
      listKT: [
        {
          id: 1,
          name: 'Cho xem, không thử',
        },
        {
          id: 2,
          name: 'Cho xem và thử hàng',
        },
        {
          id: 3,
          name: 'Không cho xem',
        },
      ],
      listCity: [],
      selectCity: null,
      listDistrict: [],
      selectDistrict: null,
      listWard: [],
      selectWard: null,
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm sản phẩm imei',
        },
      ],
      selectTypeSearch: 1,
      listType: [
        {
          id: 1,
          name: 'Mua tại quầy',
        },
        {
          id: 2,
          name: 'Đặt trước',
        },
        {
          id: 3,
          name: 'Chuyển hàng',
        },
      ],
      selectType: 1,
      listStatus: [
        {
          id: 1,
          name: 'Mới',
          disabled: false,
        },
        {
          id: 2,
          name: 'Đang xử lí',
          disabled: false,
        },
        {
          id: 3,
          name: 'Đã xác nhận',
          disabled: false,
        },
        {
          id: 4,
          name: 'Đang giao',
          disabled: false,
        },
        {
          id: 5,
          name: 'Đang đóng gói',
          disabled: false,
        },
        {
          id: 6,
          name: 'Thành công',
          disabled: false,
        },
        {
          id: 7,
          name: 'Khách hủy',
          disabled: false,
        },
        {
          id: 8,
          name: 'Hệ thống hủy',
          disabled: false,
        },
        {
          id: 9,
          name: 'Đã chuyển hoàn',
          disabled: false,
        },
      ],
      selectCancelStatus: 0,
      listCancelStatus: [
        {
          id: 1,
          name: 'Đã mua tại quầy',
        },
        {
          id: 2,
          name: 'Đơn trùng',
        },
        {
          id: 3,
          name: 'Đã mua nơi khác',
        },
        {
          id: 4,
          name: 'Không liên hệ được khách hàng',
        },
        {
          id: 5,
          name: 'Khách suy nghĩ thêm',
        },
        {
          id: 6,
          name: 'Hết hàng',
        },
        {
          id: 7,
          name: 'Lý do khác',
        },
      ],
      selectStatus: null,
      placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
      listOrderItem: [],
      discount: 0,
      customerName: '',
      priceDebit: 0,
      address: '',
      deposit: 0,
      datePay: '',
      note: '',
      dateCome: '',
      timeCome: '',
      search: '',
      noteCustomer: '',
      dpConfigs: {
        datePay: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
          allowInputToggle: true,
        },
        dateCome: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
          allowInputToggle: true,
        },
        timePicker: {
          format: 'LT',
          useCurrent: true,
          sideBySide: true,
          locale: 'vi',
          allowInputToggle: true,
        },
      },
      format: 'dd-MM-yyyy',
      language: vi,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      listEmployee: [],
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      searchEmployee: '',
      listCustomer: [],
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      selectDiscountType: 1,
      listDiscountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      codeShip: '',
      countProduct: 0,
      proSTT: 0,
      checkAllPro: false,
      productNote: '',
      noteBillItem: '',
      productIdUpdateNote: null,
      fieldProductPromotion: [
        {
          key: 'selected',
          label: '',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'name',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'quantityInStock',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'returnPrice',
          label: 'Giá chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'code', label: 'Mã BPK' },
      ],
      listProductPromotion: [],
      addProPromotionForBillItemId: null,
      countProductPromotion: 0,
      selectedProductPromotion: '',
      modalTitle: '',
      isSearching: false,
      creditAmount: 0,
      creditCode: '',
      installedMoneyAmount: 0,
      filteredCreditAccOptions: [],
      filteredCreditFeeAccOptions: [],
      creditAccOptions: [
        {
          data: [],
        },
      ],
      creditFeeAccOptions: [
        {
          data: [],
        },
      ],
      installmentCode: '',
      transferReferenceCode: '',
      creditCardCode: '',
      creditCardFee: 0,
      creditFeeRate: 0,
      listPayCreditFeeType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: 'Chuyển khoản',
        },
        {
          id: 3,
          name: 'Quẹt thẻ',
        },
      ],
      selectedPayCreditFeeType: 1,
      selectedCreditFeeAcc: '',
      selectedCashAccount: null,
      selectedCashAccountCode: '',
      selectedClassAccountantCode: '',
      selectedClassCreditAcc: null,
      selectedClassCreditAccCode: '',
      selectedClassCreditFeeAcc: null,
      selectedClassCreditFeeAccCode: '',
      selectedInstallmentAccount: null,
      selectedInstallmentAccountCode: '',
      selectedCreditAcc: '',
      filteredOptionsInstallment: [],
      optionsInstallment: [
        {
          data: [],
        },
      ],
      customerIndentifyNo: '',
      selectedInstallment: '',
    };
  },
  components: {
    KTCodePreview,
    OrderItem,
    datePicker,
    OrderTransactions,
  },
  computed: {
    selectedClassAccountant() {
      const acc = this.listAccSearch.find(
        (p) => p.name === this.selectedAccountant,
      );
      if (acc) {
        return acc.id;
      } else {
        return null;
      }
    },
    selectedClassCashAcc() {
      const acc = this.listCashAccSearch.find(
        (p) => p.name === this.selectedCashAcc,
      );
      if (acc) {
        return acc.id;
      } else {
        return null;
      }
    },
    refundPrice() {
      let total = 0;
      for (let index = 0; index < this.listOrderItem.length; index++) {
        const element = this.listOrderItem[index];
        if (element.orderItemType === 1) {
          total +=
            unMaskPrice(element.price) * element.quantity -
            unMaskPrice(element.discount);
        } else if (element.orderItemType === 2 && element.checked === false) {
          total -= unMaskPrice(element.returnPrice) * element.quantity;
        } else if (element.orderItemType === 3 && element.checked === true) {
          total += unMaskPrice(element.returnPrice) * element.quantity;
        }
      }
      let customerShipFee = parseInt(unMaskPrice(this.customerShipFee));
      let shipFee = parseInt(unMaskPrice(this.shipFee));
      let priceDebit = parseInt(unMaskPrice(this.priceDebit));
      let deposit = parseInt(unMaskPrice(this.deposit));
      let creditAmount = parseInt(unMaskPrice(this.creditAmount));
      let installedMoneyAmount = parseInt(
        unMaskPrice(this.installedMoneyAmount),
      );

      return total > 0
        ? total -
            (priceDebit +
              deposit +
              creditAmount +
              installedMoneyAmount +
              shipFee) +
            customerShipFee
        : priceDebit +
            deposit +
            creditAmount +
            installedMoneyAmount -
            customerShipFee;
    },
    collectPrice() {
      let total = 0;
      for (let index = 0; index < this.listOrderItem.length; index++) {
        const element = this.listOrderItem[index];
        if (element.orderItemType === 1) {
          total +=
            unMaskPrice(element.price) * element.quantity -
            unMaskPrice(element.discount);
        } else if (element.orderItemType === 2 && element.checked === false) {
          total -= unMaskPrice(element.returnPrice) * element.quantity;
        } else if (element.orderItemType === 3 && element.checked === true) {
          total += unMaskPrice(element.returnPrice) * element.quantity;
        }
      }
      let customerShipFee = parseInt(unMaskPrice(this.customerShipFee));
      let shipFee = parseInt(unMaskPrice(this.shipFee));
      let priceDebit = parseInt(unMaskPrice(this.priceDebit));
      let deposit = parseInt(unMaskPrice(this.deposit));
      let creditAmount = parseInt(unMaskPrice(this.creditAmount));
      let installedMoneyAmount = parseInt(
        unMaskPrice(this.installedMoneyAmount),
      );
      return total > 0
        ? total -
            (priceDebit + deposit + creditAmount + installedMoneyAmount) +
            customerShipFee
        : priceDebit +
            deposit +
            creditAmount +
            installedMoneyAmount -
            customerShipFee +
            shipFee;
    },
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listOrderItem.length; index++) {
        const element = this.listOrderItem[index];
        if (element.orderItemType === 1) {
          total +=
            unMaskPrice(element.price) * element.quantity -
            unMaskPrice(element.discount);
        } else if (element.orderItemType === 2 && element.checked === false) {
          total -= unMaskPrice(element.returnPrice) * element.quantity;
        } else if (element.orderItemType === 3 && element.checked === true) {
          total += unMaskPrice(element.returnPrice) * element.quantity;
        }
      }
      return total;
    },
    selectedClassStore() {
      const store = this.listStore.find((s) => s.name === this.searchStore);
      if (store) {
        return store.id;
      } else {
        return null;
      }
    },
    selectedClassEmployee() {
      const employee = this.listEmployee.find(
        (s) => s.fullName === this.searchEmployee,
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedClassCustomer: {
      get: function() {
        const customer = this.listCustomer.find(
          (s) => s.phoneNo === this.searchCustomer,
        );
        if (customer) {
          return customer.id;
        } else {
          return this.searchCustomer;
        }
      },
      set: function(id) {
        return (this.customerId = id);
      },
    },
    selectedClassProduct() {
      const product = this.listProduct.find(
        (p) => p.productName === this.searchProduct,
      );
      if (product) {
        return product;
      } else {
        return null;
      }
    },
  },
  created() {
    this.fetchCity();
    this.getListOrderSource();
    this.getListEmployee();
    this.fetchCashAcc();
    this.fetchInstallments();
    this.fetchOrderHistory();
    this.id = this.$route.query.id;
    this.getOrderById();
  },
  mounted() {

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Đơn hàng', route: '/orders' },
      { title: 'Danh sách đơn hàng', route: '/orders' },
      { title: 'Cập nhật đơn hàng' },
    ]);
    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function(/*wizardObj*/) {});

    wizard.on('change', function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    getOrderStatus(id) {
      let result = '';
      for (let index = 0; index < this.listStatus.length; index++) {
        const element = this.listStatus[index];
        if (element.id === id) {
          result = element.name;
        }
      }
      return result;
    },
    getHistoryTypeName(typeId) {
      switch (typeId) {
        case 1:
          return 'đơn hàng';
        case 2:
          return 'hóa đơn';
        case 3:
          return 'sản phẩm';
        default:
          return '';
      }
    },
    getOrderTypeByName(typeId) {
      switch (typeId) {
        case 1:
          return 'Mua tại quầy';
        case 2:
          return 'Đặt trước';
        case 3:
          return 'Chuyển hàng';
        default:
          return '';
      }
    },
    fetchOrderHistory: async function() {
      let params = {
        //  loại đơn hàng
        type: 1,
      };
      await ApiService.setHeader();
      await ApiService.query('/histories/getByReferenceId/' + this.id, {
        params,
      }).then((res) => {
        res.data.data.map((x) => {
          let data = {
            id: x.id,
            type: x.type,
            referenceId: x.referenceId,
            action: x.action,
            description: x.description,
            originalValue: x.originalValue,
            newValue: x.newValue,
            updatedBy: x.updatedBy,
            createdAt: moment(String(x.createdAt)).format('DD/MM/YYYY HH:mm'),
            updatedAt: moment(x.updatedAt).format('DD/MM HH:mm'),
          };
          this.histories.push(data);
        });
      });
    },
    validate() {
      this.errors = [];
      if (
        (this.selectStatus === 8 || this.selectStatus === 7) &&
        !this.selectCancelStatus
      ) {
        this.errors.push('Thiếu lý do');
      }
      if (unMaskPrice(this.priceDebit) > 0 && !this.selectedAccountant) {
        this.errors.push('Thiếu tài khoản chuyển khoản');
      }

      if (unMaskPrice(this.deposit) > 0 && !this.selectedCashAcc) {
        this.errors.push('Thiếu tài khoản thanh toán tiền mặt');
      }

      if (this.errors.length == 0) {
        this.updateOrder();
      } else {
        this.errors.forEach((element) => {
          this.makeToastFaile(element);
        });
      }
    },
    fetchCashAcc: async function() {
      this.listCashAccSearch = [];
      ApiService.setHeader();
      if (this.selectedClassStore !== null) {
        let params = {
          storeId: this.selectedClassStore,
          //  tk tien mat
          type: 1,
        };
        ApiService.query('accountants/getByStore', { params }).then(
          (response) => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach((item) => {
              if (item.level >= 2) {
                let acc = {
                  id: item.id,
                  name: item.name,
                  code: item.code,
                };
                this.listCashAccSearch.push(acc);
                this.cashAccOptions[0].data.push(acc.name);
              }
            });
            this.filteredCashAccOptions = [
              { data: this.cashAccOptions[0].data },
            ];
            if (this.selectedPayCreditFeeType === 1) {
              this.creditFeeAccOptions[0].data = this.listCashAccSearch;
              let tmp = [{ data: this.creditFeeAccOptions[0].data }];
              this.filteredCreditFeeAccOptions = tmp;
            }
          },
        );
      } else {
        let params = {
          //  tk tien mat
          type: 1,
        };
        ApiService.query('accountants/getByType', { params }).then(
          (response) => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach((item) => {
              if (item.level >= 2) {
                let acc = {
                  id: item.id,
                  name: item.name,
                  code: item.code,
                };
                this.listCashAccSearch.push(acc);
                this.cashAccOptions[0].data.push(acc.name);
              }
            });
            this.filteredCashAccOptions = [
              { data: this.cashAccOptions[0].data },
            ];
            if (this.selectedPayCreditFeeType === 1) {
              this.creditFeeAccOptions[0].data = this.listCashAccSearch;
              let tmp = [{ data: this.creditFeeAccOptions[0].data }];
              this.filteredCreditFeeAccOptions = tmp;
            }
          },
        );
      }
    },
    fetchAccountant: async function() {
      this.listAccSearch = [];
      ApiService.setHeader();
      let params = {
        //  tk ngan hang
        type: 2,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        this.accountantOptions[0].data = [];
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            let acc = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.listAccSearch.push(acc);
            this.accountantOptions[0].data.push(acc.name);
            this.creditAccOptions[0].data.push(acc);
          }
        });
        this.filteredAccOptions = [{ data: this.accountantOptions[0].data }];
        if (
          this.selectedPayCreditFeeType === 2 ||
          this.selectedPayCreditFeeType === 3
        ) {
          this.creditFeeAccOptions[0].data = this.listAccSearch;
          let tmp = [{ data: this.creditFeeAccOptions[0].data }];
          this.filteredCreditFeeAccOptions = tmp;
        }
      });
    },
    onInputCashAccChange(text) {
      this.selectedCashAcc = text;

      const filteredData = this.cashAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onCashAccSelected(option) {
      this.selectedCashAcc = option.item;
    },
    onInputAccChange(text) {
      this.selectedAccountant = text;

      const filteredData = this.accountantOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onAccSelected(option) {
      this.selectedAccountant = option.item;
    },
    checkDebitPrice() {
      return unMaskPrice(this.priceDebit) > 0;
    },
    checkDepositPrice() {
      return unMaskPrice(this.deposit) > 0;
    },
    checkValidImei(value) {
      this.isValidImei = value;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    viewInventory() {
      this.showInventory = !this.showInventory;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    findCustomerByName() {
      const customer = this.listCustomer.find(
        (s) => s.phoneNo === this.searchCustomer,
      );
      if (customer) {
        this.customerId = customer.id;
      } else {
        return this.searchCustomer;
      }
    },
    getOrderById: async function() {
      await this.fetchStore();
      await ApiService.setHeader();
      await ApiService.query('orders' + '/' + this.id).then(
        async (response) => {
          const {
            storeId,
            status,
            cancelStatus,
            customerNote,
            checkDate,
            checkTime,
            codeShip,
            discountAmount,
            discountType,
            transferAmount,
            depositAmount,
            paymentDate,
            customerShipFee,
            shipFee,
            orderSourceId,
            saleNote,
            customerId,
            orderItems,
            createdAt,
            createdBy,
            cashAccountName,
            transferAccountName,
            editable,
            orderType,
          } = response.data.data;
          this.selectType = orderType;
          const store = this.listStore.find((s) => s.id === storeId);
          this.editable = editable;
          this.listOrderItem = [];
          this.searchStore = store ? store.name : '';
          this.selectedClassCustomer = customerId;
          await this.getCustomerInfoById();
          // await this.getDistrict();
          // await this.getWard();

          this.selectCancelStatus = cancelStatus;
          this.selectStatus = status;
          this.noteCustomer = customerNote;
          this.note = saleNote;
          this.dateCome = checkDate
            ? moment(checkDate).format('DD/MM/YYYY')
            : null;
          this.timeCome = checkTime;
          this.codeShip = codeShip;
          this.discount = discountAmount;
          this.priceDebit = transferAmount;
          this.deposit = depositAmount;
          this.datePay = paymentDate
            ? moment(paymentDate).format('DD/MM/YYYY')
            : null;
          this.customerShipFee = customerShipFee;
          this.shipFee = shipFee;
          this.selectProvider = orderSourceId;
          this.createdAt = moment(createdAt).format('HH:mm DD-MM-YYYY');
          this.createdBy = createdBy;
          this.selectedCashAcc = cashAccountName;
          this.selectedAccountant = transferAccountName;
          this.selectDiscountType = discountType;
          this.creditAmount = response.data.data.creditAmount;
          this.installedMoneyAmount = response.data.data.installedMoneyAmount;
          this.installmentCode = response.data.data.installMoneyCode;
          this.creditCode = response.data.data.creditCode;
          this.selectedCreditAcc = response.data.data.creditAccName;
          this.selectedInstallment = response.data.data.installmentAccName;
          this.transferReferenceCode = response.data.data.transferReferenceCode;
          this.creditCardCode = response.data.data.creditCardNo;
          this.creditCardFee = response.data.data.otherFees;
          this.selectedCreditFeeAcc = response.data.data.creditFeeAccName;
          this.selectedPayCreditFeeType = response.data.data.payCreditFeeType;
          this.selectedClassCreditAcc = response.data.data.creditAccountId;
          this.selectedClassCreditFeeAcc = response.data.data.creditFeeAcountId;
          this.selectedInstallmentAccount =
            response.data.data.installMoneyAccountId;
          this.customerIndentifyNo = response.data.data.customerIndentifyNo;
          this.fetchCreditAcc();
          this.fetchAccountant();
          this.getCreditFee();
          this.fetchInstallments();
          this.fetchCashAcc();
          for (let index = 0; index < this.listStatus.length; index++) {
            let element = this.listStatus[index];
            if (element.id < status) {
              element.disabled = true;
            }
          }

          orderItems.forEach((x) => {
            let code = '';
            if (x.orderItemType === 1) {
              this.proSTT++;
              code = x.productCode;
            } else {
              code = 'Quà tặng kèm';
            }
            let orderItem = {
              id: x.id,
              productId: x.productId,
              name: x.productName,
              code: code,
              price: x.productPrice,
              type: x.productType,
              orderItemType: x.orderItemType,
              quantity: x.quantity,
              imeiCode: x.imeiCode,
              totalPrice: x.totalAmount,
              discount: x.discountAmount,
              storeId: storeId,
              comboItems: x.listProductInCombo,
              listAccessoryBonus: [],
              parentId: x.belongOrderDetailId,
              countItem: this.proSTT,
              returnPrice: x.repurchasePrice,
              checked: x.isGiftTaken,
              note: x.note,
              deleted: x.deleted,
            };
            this.listOrderItem.push(orderItem);
          });
        },
      );
    },
    onSelected(option) {
      this.searchStore = option.item;
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item;
      //this.getProductStock();
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.findCustomerByName();
      this.getCustomerInfoById();
    },
    onSelectedProduct(option) {
      this.searchProduct = '';
      this.countProduct++;
      this.proSTT++;
      let tempId = uuidv4();
      let orderItem = {
        id: tempId,
        name: option.item.productName,
        code: option.item.productCode,
        productId: option.item.productId,
        type: option.item.productType,
        price: option.item.sellingPrice,
        quantity: 1,
        quantityInStock: option.item.quantity,
        totalPrice: option.item.sellingPrice,
        discount: 0,
        storeId: this.selectedClassStore,
        comboItems: option.item.listProductInCombo,
        listAccessoryBonus: option.item.listAccessoryBonus,
        listAccessoryPromotion: option.item.listAccessoryPromotion,
        deleted: 0,
        tempId: tempId,
        orderItemType: 1,
        note: '',
        countItem: this.proSTT,
        returnPrice: 0,
        parentId: null,
        checked: true,
        imeiCode: option.item.imeiCode,
      };

      const listAccBonus = this.getAccessoryItems(
        orderItem.listAccessoryBonus,
        orderItem.id,
      );

      if (orderItem.type === 1 || orderItem.type === 3) {
        if (this.listOrderItem.length > 0) {
          let checkItem = this.listOrderItem.filter(
            (x) => x.id === orderItem.id,
          );
          if (checkItem.length > 0) {
            for (let i = 0; i < this.listOrderItem.length; i++) {
              if (orderItem.id === this.listOrderItem[i].id) {
                this.listOrderItem[i].quantity++;
              }
            }
          } else {
            this.listOrderItem.push(orderItem);
            this.listOrderItem.push(...listAccBonus);
          }
        } else {
          this.listOrderItem.push(orderItem);
          this.listOrderItem.push(...listAccBonus);
        }
      } else {
        this.listOrderItem.push(orderItem);
        this.listOrderItem.push(...listAccBonus);
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      //   this.selectDistrict = null;
      //   this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      //   this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onInputChange(text) {
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      // this.getProductStock();
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [
        {
          data: this.optionsCustomer[0].data,
        },
      ];
      this.searchCustomer = text;
      this.debounceInput();
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    fetchStore: async function() {
      this.options[0].data = [];
      await ApiService.setHeader();
      await ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.listStore = stores;
        stores.map((element) => {
          let storeName = element.name;
          this.options[0].data.push(storeName);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    getListOrderSource() {
      ApiService.setHeader();
      ApiService.get(`orderSources/getAll`).then((data) => {
        this.listProvider = data.data.data;
      });
    },
    getListEmployee() {
      ApiService.setHeader();
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        this.listEmployee = employees;
        employees.map((element) => {
          let employeeName = element.fullName;
          this.optionsEmployee[0].data.push(employeeName);
        });
      });
    },
    getListCustomer(textSearch) {
      this.isSearching = true;
      ApiService.setHeader();
      ApiService.get(`customer/get-by-phone?phoneNumber=${textSearch}`).then(
        (data) => {
          const customers = data.data.data;
          this.listCustomer = customers;
          this.optionsCustomer[0].data = customers || [];
          this.isSearching = false;
          this.filteredOptionsCustomer = [
            {
              data: this.optionsCustomer[0].data,
            },
          ];
          if (this.searchCustomer !== textSearch) {
            let nextSearch = this.searchCustomer;
            this.getListCustomer(nextSearch);
          }
        },
      );
    },
    getCustomerInfoById: async function() {
      await ApiService.setHeader();
      await ApiService.get(`customer/${this.customerId}`).then((response) => {
        if (response.data.status === 1) {
          let {
            fullName,
            city,
            ward,
            district,
            address,
            phoneNo,
          } = response.data.data;
          this.customerName = fullName;
          this.selectCity = city;
          this.selectWard = ward;
          this.selectDistrict = district;
          this.address = address;
          this.customerPhone = phoneNo;
          this.searchCustomer = phoneNo;
          if (this.selectCity !== null) {
            this.getDistrict();
          }
          if (this.selectDistrict !== null) {
            this.getWard();
          }
        } else {
          this.makeToastFaile(response.data.message);
        }
      });
    },
    changePlaceholder() {
      if (this.selectTypeSearch === 1) {
        this.placeholder = 'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
      } else if (this.selectTypeSearch === 2) {
        this.placeholder = 'Tìm kiếm theo mã imei';
      }
    },
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        if (this.selectTypeSearch === 1) {
          this.fetchProduct(textSearch, 1);
        } else if (this.selectTypeSearch === 2) {
          this.fetchProductImei(textSearch);
        }
      }
    },
    fetchProductImei(textSearch) {
      this.isSearching = true;
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/search-by-imei?storeId=${this.selectedClassStore}&imeiCode=${textSearch}`,
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          let item = {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            barCode: element.barCode,
            quantityInStock: element.quantityInStock,
            sellingPrice: element.sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            productType: element.productType,
            listProductInCombo: element.listProductInCombo,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
            imeiCode: element.imeiCode,
          };
          this.optionsProduct[0].data.push(item);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          this.searchProductAPI();
        }
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?searchProduct=${textSearch}&storeId=${this.selectedClassStore}`,
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          this.fetchProduct(this.searchProduct);
        }
      });
    },
    deleteItemInArray(item) {
      if (item.type === 2 && item.tempId !== undefined) {
        for (var index = 0; index < this.listOrderItem.length; index++) {
          if (this.listOrderItem[index].tempId === item.tempId) {
            this.listOrderItem[i].deleted = true;
          }
        }
      } else {
        for (var i = 0; i < this.listOrderItem.length; i++) {
          if (this.listOrderItem[i].id === item.id) {
            this.listOrderItem[i].deleted = true;
          }
        }
      }
    },
    updateItem(item) {
      this.listOrderItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listOrderItem[index].name = item.name;
          if (item.imeiCode !== null && item.imeiCode !== '') {
            this.listOrderItem[index].imeiCode = item.imeiCode;
          }
          this.listOrderItem[index].productId = item.productId;
          this.listOrderItem[index].price = item.price;
          this.listOrderItem[index].quantity = item.quantity;
          this.listOrderItem[index].totalPrice = item.totalPrice;
          this.listOrderItem[index].discount = item.discount;
        }
      });
    },
    updateOrder() {
      if (!this.isValidImei) return;
      let listPro = [];
      this.listOrderItem.forEach((element) => {
        let item = {
          id: element.id,
          productId: element.productId,
          imeiCode: element.imeiCode,
          productPrice: unMaskPrice(element.price),
          quantity: element.quantity,
          discountAmount:
            element.discount !== '' ? unMaskPrice(element.discount) : 0,
          isGiftTaken: element.checked,
          repurchasePrice: unMaskPrice(element.returnPrice),
          note: element.note,
          productType: element.orderItemType,
          belongOrderDetailId: element.parentId,
        };
        listPro.push(item);
      });
      let checkDate = this.dateCome
        ? moment(this.dateCome, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null;
      let paymentDate = this.datePay
        ? moment(this.datePay, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null;
      let data = {
        id: this.id,
        customerPhone: this.customerPhone,
        customerName: this.customerName,
        customerAddress: this.address,
        customerDistrict: this.selectDistrict,
        customerCity: this.selectCity,
        customerWard: this.selectWard,
        listOrderItem: listPro,
        storeId: this.selectedClassStore,
        orderSourceId: this.selectProvider,
        saleNote: this.note,
        checkDate: checkDate,
        checkTime: this.timeCome,
        orderType: this.selectType,
        status: this.selectStatus,
        cancelStatus: this.selectCancelStatus,
        customerNote: this.noteCustomer,
        discountType: this.selectDiscountType,
        discountAmount: this.discount,
        transferAmount: this.priceDebit ? unMaskPrice(this.priceDebit) : 0,
        depositAmount: this.deposit ? unMaskPrice(this.deposit) : 0,
        paymentDate: paymentDate,
        codeShip: this.codeShip,
        customerShipFee: this.customerShipFee
          ? unMaskPrice(this.customerShipFee)
          : 0,
        shipFee: this.shipFee ? unMaskPrice(this.shipFee) : 0,
        transferAccountId: this.selectedClassAccountant,
        cashAccountId: this.selectedClassCashAcc,
        creditAccountId: this.selectedClassCreditAcc,
        creditAmount: this.creditAmount ? unMaskPrice(this.creditAmount) : 0,
        creditCode: this.creditCode,
        installedMoneyAmount: this.installedMoneyAmount
          ? unMaskPrice(this.installedMoneyAmount)
          : 0,
        installMoneyCode: this.installmentCode,
        installMoneyAccountId: this.selectedInstallmentAccount,
        customerIndentifyNo: this.customerIndentifyNo,
        transferReferenceCode: this.transferReferenceCode,
        otherFees: this.creditCardFee ? unMaskPrice(this.creditCardFee) : 0,
        creditCardNo: this.creditCardCode,
        payCreditFeeType: this.selectedPayCreditFeeType,
        creditFeeAcountId: this.selectedClassCreditFeeAcc,
      };
      ApiService.setHeader();
      ApiService.put('orders', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.$router.push({
              name: 'list-order',
              params: { updateOrderSuccess: true, message: data.message },
            });
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    showModalAddProductPromotion(id) {
      this.addProPromotionForBillItemId = id;
      this.countProductPromotion = 0;
      this.listProductPromotion = [];
      this.checkAllPro = false;
      for (var i = 0; i < this.listOrderItem.length; i++) {
        if (this.listOrderItem[i].id === this.addProPromotionForBillItemId) {
          this.modalTitle =
            'Thêm SP bán kèm cho sản phẩm ' + this.listOrderItem[i].name;
          let listProductAttach = this.listOrderItem[i].listAccessoryPromotion;
          listProductAttach.forEach((element) => {
            let code = `SP bán kèm- ${element.accessoryGroupCode}`;
            this.countProduct++;
            let accessoryPromotion = {
              id: this.countProduct,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.sellingPrice,
              quantity: 1,
              type: element.productType,
              imeiCode: '',
              totalPrice: element.sellingPrice,
              discount: '',
              quantityInStock: element.quantityInStock,
              storeId: this.selectedClassStore,
              billItemType: 3,
              checked: false,
              parentId: this.addProPromotionForBillItemId,
              returnPrice: element.price,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              listProductVariant: element.listProductVariant,
            };
            this.listProductPromotion.push(accessoryPromotion);
          });
        }
      }
      this.$refs['add-product-promotion-modal'].show();
    },
    hideModalAddProductPromotion() {
      this.$refs['add-product-promotion-modal'].hide();
    },
    clickAll() {
      this.listProductPromotion.forEach((element, index) => {
        this.listProductPromotion[index].checked = !this.checkAllPro;
      });
    },
    clickIndex(id) {
      let check = true;
      for (let index = 0; index < this.listProductPromotion.length; index++) {
        if (this.listProductPromotion[index].id === id) {
          let checked = this.listProductPromotion[index].checked;
          this.listProductPromotion[index].checked = !checked;
        }
      }
      for (let index = 0; index < this.listProductPromotion.length; index++) {
        const element = this.listProductPromotion[index];
        if (element.checked === false) {
          check = false;
          break;
        }
      }
      this.checkAllPro = check;
    },
    showModalAddNote(id) {
      this.productIdUpdateNote = id;
      for (var i = 0; i < this.listOrderItem.length; i++) {
        if (this.listOrderItem[i].id === id) {
          this.productNote = this.listOrderItem[i].name;
          this.noteBillItem = this.listOrderItem[i].note;
        }
      }
      this.$refs['add-note-modal'].show();
    },
    hidenModalAddNote() {
      this.$refs['add-note-modal'].hide();
    },
    updateNote() {
      for (var i = 0; i < this.listOrderItem.length; i++) {
        if (this.listOrderItem[i].id === this.productIdUpdateNote) {
          this.listOrderItem[i].note = this.noteBillItem;
        }
      }
      this.$refs['add-note-modal'].hide();
    },
    addProductPromotionToListBillItem() {
      this.listProductPromotion.forEach((element) => {
        if (element.checked) {
          this.countProduct++;
          let accessoryPromotion = {
            id: this.countProduct,
            productId: element.productId,
            name: element.name,
            code: element.code,
            price: element.price,
            quantity: 1,
            type: element.type,
            imeiCode: '',
            totalPrice: element.price,
            discount: '',
            quantityInStock: element.quantityInStock,
            storeId: this.selectedClassStore,
            orderItemType: 3,
            checked: true,
            parentId: this.addProPromotionForBillItemId,
            returnPrice: element.returnPrice,
            accessoryGroupId: '',
            accessoryGroupCode: '',
          };
          this.listOrderItem.push(accessoryPromotion);
        }
      });
      this.listOrderItem = this.sortListBillItem(this.listOrderItem);
      this.hideModalAddProductPromotion();
    },
    debounceInput: decounce(function() {
      if (!this.isSearching) this.getListCustomer(this.searchCustomer);
    }, TIME_TRIGGER),
    debounceInputProduct: decounce(function() {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    getAccessoryItems(array, tempId) {
      let result = [];
      array.forEach((x, index) => {
        let currentId = index + 1;
        this.countProduct++;
        let accessoryBonus = {
          id: uuidv4(),
          productId: x.productId,
          name: x.productName,
          code: x.accessoryGroupCode,
          price: x.sellingPrice,
          type: 1,
          orderItemType: 2,
          quantity: 1,
          discount: '',
          imeiCode: '',
          totalPrice: x.sellingPrice,
          storeId: this.selectedClassStore,
          quantityInStock: x.quantityInStock,
          returnPrice: x.price,
          checked: true,
          accessoryGroupId: x.accessoryGroupId,
          accessoryGroupCode: x.accessoryGroupCode,
          listProductVariant: x.listProductVariant,
          parentId: tempId,
          countItem: this.countProduct + currentId / 100,
        };
        result.push(accessoryBonus);
      });
      return result;
    },
    checkCreditPrice() {
      return unMaskPrice(this.creditAmount) > 0;
    },
    checkInstallmentAmount() {
      return unMaskPrice(this.installedMoneyAmount) > 0;
    },
    onCreditAccSelected(option) {
      this.selectedCreditAcc = option.item.name;
      this.selectedClassCreditAcc = option.item.id;
      this.selectedClassCreditAccCode = option.item.code;
    },
    onCreditFeeAccSelected(option) {
      this.selectedCreditFeeAcc = option.item.name;
      this.selectedClassCreditFeeAcc = option.item.id;
      this.selectedClassCreditFeeAccCode = option.item.code;
    },
    onInputInstallmentChange(text) {
      this.selectedInstallment = text;
      const filteredData = this.optionsInstallment[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsInstallment = [
        {
          data: filteredData,
        },
      ];
    },
    onInstallmentSelected(option) {
      this.selectedInstallment = option.item.name;
      this.selectedInstallmentAccount = option.item.id;
      this.selectedInstallmentAccountCode = option.item.code;
    },
    fetchCreditAcc: async function() {
      ApiService.setHeader();
      let params = {
        //  tk quet the
        type: 3,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        //this.creditAccOptions[0].data = [];
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            let acc = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.creditAccOptions[0].data.push(acc);
          }
        });
        let tmp = [{ data: this.creditAccOptions[0].data }];
        this.filteredCreditAccOptions = tmp;
      });
    },
    fetchInstallments: async function() {
      let params = {
        page: 1,
        pageSize: 1000,
      };
      this.listInstallment = [];
      await ApiService.setHeader();
      await ApiService.query('/installments', { params }).then((response) => {
        this.listInstallment = response.data.data.result;
        this.optionsInstallment[0].data = [];
        this.listInstallment.forEach((element) => {
          this.optionsInstallment[0].data.push(element);
        });
        let tmp = [{ data: this.optionsInstallment[0].data }];
        this.filteredOptionsInstallment = tmp;
      });
    },
    onInputCreditAccChange(text) {
      this.selectedCreditAcc = text;
      const filteredData = this.creditAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCreditAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onInputCreditFeeAccChange(text) {
      this.selectedCreditFeeAcc = text;
      const filteredData = this.creditFeeAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCreditFeeAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    getCreditFee: async function() {
      await ApiService.setHeader();
      await ApiService.query('settings/credit-fee').then((res) => {
        this.creditFeeRate = res.data.data;
      });
    },
    onCreditAmountChange() {
      this.creditCardFee =
        (unMaskPrice(this.creditAmount) * this.creditFeeRate) / 100;
    },
    onCreditFeePayTypeChange() {
      if (this.selectedPayCreditFeeType === 1) {
        this.creditFeeAccOptions[0].data = this.listCashAccSearch;
        let tmp = [{ data: this.creditFeeAccOptions[0].data }];
        this.filteredCreditFeeAccOptions = tmp;
      } else {
        this.creditFeeAccOptions[0].data = this.listAccSearch;
        let tmp = [{ data: this.creditFeeAccOptions[0].data }];
        this.filteredCreditFeeAccOptions = tmp;
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.phoneNo;
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: rgb(190, 190, 200);
}

.headerTable {
  color: rgb(24, 28, 50);
}

.form-group label {
  font-weight: 500;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
