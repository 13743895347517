<template>
  <tr v-show="!productItem.deleted" class="heighRow">
    <td class="tdCenter text-center">
      <span v-if="productItem.orderItemType === 1">{{
        productItem.countItem
      }}</span>
      <span v-else>
        <b-form-checkbox
          v-model="productItem.checked"
          size="lg"
          @change="checkvalidation"
          :disabled="!editable"
        ></b-form-checkbox>
      </span>
    </td>
    <td class="tdCenter">
      <div>
        <p
          style="font-weight: 500"
          class="m-0"
          v-show="
            (productItem.code !== '' && productItem.type === 1) ||
            productItem.type === 2 ||
            (productItem.code !== '' && productItem.type === 4)
          "
        >
          ({{ productItem.code }})
        </p>
        <p
          v-show="
            productItem.type === 1 ||
            productItem.type === 2 ||
            productItem.type === 4
          "
          style="font-weight: 600"
          class="m-0"
        >
          {{ productItem.name }}
        </p>
        <b-form-group v-show="productItem.type === 2">
          <b-form-input
            id="input-imei"
            :state="
              itemChoice.imeiCode
                ? this.$emit('validateState', itemChoice.imeiCode)
                : null
            "
            v-model="$v.itemChoice.imeiCode.$model"
            placeholder="Nhập mã IMEI"
            class="form-control form-control-sm col-8"
            v-on:input="isAvailableQuantity(itemChoice.imeiCode)"
            :disabled="!editable"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="$v.itemChoice.imeiCode.minLength"
            id="input-imei-live-feedback"
            >Vui lòng nhập imei code > 3 kí tự</b-form-invalid-feedback
          >
          <b-form-invalid-feedback
            :state="validate"
            id="input-imei-live-feedback"
            >Không đủ tồn kho sản phẩm</b-form-invalid-feedback
          >
        </b-form-group>

        <div v-show="productItem.comboItems !== undefined">
          <table class="table-responsive">
            <tr style="display: table-caption">
              <td style="border: none">
                <p style="font-weight: 600" class="m-0">
                  {{ productItem.name }}
                </p>
              </td>
            </tr>
            <tr
              v-for="(items, index) in productItem.comboItems"
              :key="index"
              style="border-top: 1px solid gray"
            >
              <td style="border: none">
                <i style="color: rgb(24, 28, 50)" class="fas fa-cube"></i>
              </td>
              <td style="border: none; width: -webkit-fill-available">
                {{ items.productName }}
                <i style="font-size: unset; color: unset"
                  >({{ formatMoney(items.sellingPrice) }})</i
                >
              </td>
              <td style="border: none">{{ items.quantity }}</td>
            </tr>
          </table>
        </div>
        <div v-if="productItem.orderItemType === 1">
          <p
            v-if="productItem.note && productItem.note.trim().length > 0"
            class="text-note"
          >
            Ghi chú: {{ productItem.note }}
          </p>
        </div>
      </div>
    </td>
    <td class="tdCenter text-right" style="width: 10%">
      <div
        v-if="editable && (productItem.type === 1 || productItem.type === 3)"
      >
        <b-form-input
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.quantity"
          type="number"
          :min="0"
          placeholder="Số lượng"
          class="w-100 text-right"
          @change="checkvalidation"
          :disabled="!editable"
        ></b-form-input>
      </div>

      <span v-else>
        <b-form-input
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.quantity"
          type="number"
          :min="0"
          class="w-100 text-right"
          :disabled="true"
        ></b-form-input>
      </span>
    </td>
    <td class="tdCenter" style="text-align: right">
      <div v-if="editable && productItem.orderItemType === 1">
        <b-form-input
          size="sm"
          style="
            height: calc(1.35rem + 1.1rem + 2px);
            border-color: #007bff;
            text-align: right;
          "
          v-model="productItem.price"
          placeholder="Đơn giá"
          type="text"
          class="w-100 h-90"
          @change="checkvalidation"
          :disabled="!editable"
        ></b-form-input>
        <p style="color: red" v-if="error">Hãy nhập đầy đủ thông tin</p>
      </div>
      <span v-else>{{ formatMoney(productItem.price) }}</span>
    </td>
    <td class="tdCenter" style="text-align: right">
      <span v-if="productItem.orderItemType === 1">{{
        formatMoney(totalPriceOfProduct)
      }}</span>
      <div v-else class="mb-2">
        <div
          v-if="
            productItem.orderItemType === 2 || productItem.orderItemType === 3
          "
        >
          <span class="text-primary">{{
            productItem.orderItemType === 2 ? 'Giá thu lại' : 'Giá chiết khấu'
          }}</span>
          <br />
          <b-form-input
            v-if="editable"
            size="sm"
            style="
              height: calc(1.35rem + 1.1rem + 2px);
              border-color: #007bff;
              text-align: right;
            "
            v-model="productItem.returnPrice"
            :placeholder="
              productItem.orderItemType === 2 ? 'Giá thu lại' : 'Giá chiết khấu'
            "
            class="w-100 h-90"
            @change="checkvalidation"
            v-mask="mask"
            :disabled="!editable"
          ></b-form-input>
          <span v-else>{{ formatMoney(productItem.returnPrice) }}</span>
        </div>
      </div>
    </td>
    <td class="tdCenter" style="text-align: right">
      <div v-if="editable && productItem.orderItemType === 1">
        <b-form-input
          size="sm"
          style="
            height: calc(1.35rem + 1.1rem + 2px);
            border-color: #007bff;
            text-align: right;
          "
          v-model="productItem.discount"
          placeholder="Chiết khấu"
          type="text"
          v-mask="mask"
          @change="checkvalidation"
          class="w-100 h-90"
          :disabled="!editable"
        ></b-form-input>
      </div>
      <span v-else>{{ formatMoney(productItem.discount) || '' }}</span>
    </td>
    <td class="tdCenter" style="width: 10%" v-if="editable">
      <div class="d-flex justify-content-center">
        <b-dropdown
          size="sm"
          id="dropdown-left"
          no-caret
          right
          v-if="productItem.orderItemType === 1"
          :disabled="!editable"
        >
          <template slot="button-content">
            <i
              style="font-size: 1rem; padding-right: 0px"
              class="flaticon2-settings"
            ></i>
          </template>
          <b-dropdown-item @click="addProductBonus">
            <span style="color: #3f4254; font-size: 12px">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>
              &nbsp; Thêm quà tặng
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            @click.prevent="addProductPromotion"
            v-if="!productItem.disabledByInstallmentProgram"
          >
            <span style="color: #3f4254; font-size: 12px">
              <i style="font-size: 1rem" class="fas fa-tags"></i>
              &nbsp; Thêm SP bán kèm
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="addNote">
            <span style="color: #3f4254; font-size: 12px">
              <i style="font-size: 1rem" class="flaticon2-notepad"></i>
              &nbsp; Thêm ghi chú
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="showDeleteAlert" v-if="!isKeepStock">
            <span style="color: #3f4254; font-size: 12px">
              <i
                style="font-size: 1rem; color: #d33"
                class="flaticon2-rubbish-bin-delete-button"
              ></i>
              &nbsp; Xóa
            </span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          :disabled="!editable"
          size="sm"
          id="dropdown-left"
          no-caret
          right
          v-else
        >
          <template slot="button-content">
            <i
              style="font-size: 1rem; padding-right: 0px"
              class="flaticon2-settings"
            ></i>
          </template>
          <b-dropdown-item @click="changeProductBonus">
            <span style="color: #3f4254; font-size: 12px">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>
              &nbsp; Đổi quà tặng
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="showDeleteAlert" v-if="!isKeepStock">
            <span style="color: #3f4254; font-size: 12px">
              <i
                style="font-size: 1rem; color: #d33"
                class="flaticon2-rubbish-bin-delete-button"
              ></i>
              &nbsp; Xóa
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </td>
  </tr>
</template>

<style>
.heighRow {
  height: 40px;
}
*:focus {
  outline: none;
}
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.text-note {
  font-size: 12px;
  font-style: italic;
}
.tdCenter {
  vertical-align: middle !important;
}
</style>

<script>
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { minLength } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import { currencyMask, unMaskPrice } from './../../utils/common';

export default {
  props: [
    'productItem',
    'index',
    'listProductSearch',
    'editable',
    'storeId',
    'status',
    'isKeepStock',
  ],
  mixins: [validationMixin],
  data() {
    return {
      error: false,
      productDetail: null,
      edit: false,
      itemChoice: {
        id: 0,
        name: '',
        productId: '',
        merchantId: 1,
        imeiCode: '',
        price: 0,
        quantity: 0,
        discount: 0,
        totalPrice: 0,
      },
      validate: true,
      mask: currencyMask,
    };
  },
  computed: {
    totalPriceOfProduct() {
      return (
        this.productItem.quantity * unMaskPrice(this.productItem.price) -
        unMaskPrice(this.productItem.discount)
      );
    },
  },
  created() {
    this.itemChoice.imeiCode = this.productItem.imeiCode;
  },
  validations: {
    itemChoice: {
      imeiCode: {
        minLength: minLength(3),
      },
    },
  },
  methods: {
    isAvailableQuantity: async function (value) {
      this.validate = false;
      let dataResponse = {
        ...this.itemChoice,
        id: this.productItem.id,
        productId: this.productItem.productId,
        imeiCode: value,
        name: this.productItem.name,
        quantity: this.productItem.quantity,
        price: this.productItem.price,
        totalPrice: this.totalPriceOfProduct,
        discount: this.productItem.discount,
      };

      if (value === '') {
        this.$emit('update', dataResponse);
        return true;
      }

      const params = {
        storeId: this.storeId,
        imei: this.itemChoice.imeiCode,
      };

      await ApiService.query('bills/check-valid-imei', {
        params,
      })
        .then((response) => {
          if (response.data.data) {
            this.validate = true;
            this.$emit('checkValidImei', this.validate);
            this.$emit('update', dataResponse);
          }
          return this.validate;
        })
        .catch(() => {
          this.$emit('checkValidImei', this.validate);
          this.$emit('update', dataResponse);
          return this.validate;
        });

      return false;
    },
    editItem() {
      this.edit = true;
      this.itemChoice.id = this.productItem.id;
      this.itemChoice.name = this.productItem.name;
      this.itemChoice.imeiCode = this.productItem.imeiCode;
      this.itemChoice.productId = this.productItem.productId;
      this.itemChoice.price = this.productItem.price;
      this.itemChoice.quantity = this.productItem.quantity;
      this.itemChoice.totalPrice = this.totalPriceOfProduct;
      this.itemChoice.discount = this.productItem.discount;
    },
    cancelEdit: function () {
      this.itemChoice = {
        id: 0,
        name: '',
        imeiCode: '',
        productId: '',
        price: 0,
        quantity: 0,
        totalPrice: 0,
        discount: 0,
      };
      this.error = false;
      this.edit = false;
    },
    checkvalidation() {
      this.error = false;
      let dataResponse = this.productItem;
      dataResponse.price = unMaskPrice(this.productItem.price);
      dataResponse.totalPrice = unMaskPrice(this.productItem.totalPrice);
      if (this.productItem.orderItemType === 1) {
        dataResponse.discount = unMaskPrice(this.productItem.discount);
      } else {
        dataResponse.discount = 0;
      }
      this.$emit('update', dataResponse);
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi đơn hàng không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', {
            id: this.productItem.id,
            productType: this.productItem.type,
            tempId: this.productItem.tempId,
            countItem: this.productItem.countItem,
          });
        }
      });
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    addProductBonus: function () {
      this.$emit('addProductBonus', this.productItem.id);
    },
    changeProductBonus: function () {
      this.$emit('changeProductBonus', this.productItem.id);
    },
    addProductPromotion: function () {
      this.$emit('addProductPromotion', this.productItem.id);
    },
    addNote: function () {
      this.$emit('addNote', this.productItem.id);
    },
  },
};
</script>
